.healthcare-nav-item {
    min-height: 35px;
}

.healthcare-breadcrumb {
    padding : 10px;
}
.upload-image {
    margin-left: 30%;
    padding: 5px;
}
