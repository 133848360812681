.main {
    width: 93.5%;
    display: grid;
    height: 100vh;
    grid-template-columns: auto;
    position: fixed;
    background-color: #F5F5F5;
    padding-bottom: 5%;
}

.main1 {
    width: 77.5%;
    display: grid;
    height: 100vh;
    grid-template-columns: auto;
    position: fixed;
    background-color: #F5F5F5;
    padding-bottom: 5%;
}

.leftcontainer {
    position: absolute;
    top: 38px;
    left: 1%;
    height: 100vh;
    width: 49%;
    background: #FFFFFF;
}

.leftcontainer1 {
    position: absolute;
    top: 38px;
    left: 1%;
    height: 100vh;
    width: 97%;
    background: #FFFFFF;
}

.rightcontainer {
    /* display: block; */
    height: 100vh;
    width: 95%;
    position: relative;
    top: 18px;
    right: 1%;
    left: 10px;
    background: #FFFFFF;
    overflow-y: scroll;
    /* Trigger vertical scroll    */
    overflow-x: hidden;
}

.servicefullright {
    position: absolute;
    top: 38px;
    left: 1%;
    height: 100vh;
    width: 97%;
    background: #FFFFFF;
}

.breadCumContainer {
    height: 120px;
    font-weight: 500;
    font-size: 10px;
    line-height: 12px;
    color: #707070;
}

.orgbox {
    margin-bottom: 5px;
    margin-top: 6px;
    height: 30px;
    position: relative;
    right: 20px;
    bottom: 3px;
    color: #707070;
    font-weight: 500px;
    font-size: 10px;
    font-family: 'Montserrat';
    font-style: normal;
    
}
.orgbox3 {
    margin-bottom: 5px;
    margin-top: 6px;
    height: 30px;
    position: relative;
    right: 20px;
    bottom: 3px;
    color: #707070;
    font-weight: 500px;
    font-size: 10px;
    font-family: 'Montserrat';
    font-style: normal;
    padding-left: 27px;
}
.breadback {
    background: rgb(15, 67, 145) !important;
    margin-bottom: 5px;
    margin-top: 6px;
    /* margin: 10px; */
    height: 30px;
    position: relative;
    right: 20px;
    /* padding-top: 1px; */
    bottom: 3px;
    color: white;
    font-weight: 500px;
    font-size: 10px;
    font-family: 'Montserrat';
    font-style: normal;
}


.rows {
    margin-left: 15px;

}
.rows3 {
    margin-left:10px;

}

i {

    border-width: 0 2px 2px 0;
    display: inline-block;
    padding: 3px;
    margin-top: 15px;
    position: relative;
    right: 15px;
    height: 10px;
    width: 10px;
}

.right {
    transform: rotate(-45deg);
}

.searchbox1 {
    margin-top: 31px;
    margin-left: 30px;
    margin-right: 285px;
    margin-bottom: 613px;
    height: 35px;
    width: 40%;
    border: 1px solid #B9B9B9;
    border-radius: 20px;
}

.editContainer {
    position: absolute;
    top: 5%;
    left: 52%;
    text-align: center;
}

.addIcon {
    background-color: #F5F5F5;
    padding: 5px;
    margin-bottom: 10px;
    border-radius: 7px;
    display: inline-block;
    margin-left: 10px;
    width: 74px;
    margin-top: 10px;
    text-align: center;
    font-size: 12px;
    font-weight: 500px;
    cursor: pointer;
}

.addIconchange {
    background-color: rgb(15, 67, 145);
    padding: 3px;
    margin-bottom: 10px;
    cursor: pointer;
    border-radius: 7px;
    display: inline-block;
    margin-left: 10px;
    width: 69px;
    margin-top: 10px;
    text-align: center;
    font-size: 12px;
    font-weight: 500px;
    color: white;
}

.Addaction {
    background-color: #F5F5F5;
    padding: 3px;
    cursor: pointer;
    /* margin-bottom: 10px; */
    border-radius: 7px;
    display: inline-block;
    margin-left: 10px;
    width: 82px;
    margin-top: 10px;
    text-align: center;
    font-size: 12px;
    font-weight: 500px;
}

/* th{
  padding-bottom: 30px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400 !important;
  font-size: 14px !important;
  line-height: 17px !important;
  color: #707070;
 } */

.tabledata {
    padding: 10px;
    margin-top: -610px;
    height: 100%;
    color: #707070;

}
.css-1o9s3wi-MuiInputBase-input-MuiOutlinedInput-input {
    padding: 4.5px 11px !important;
    border-radius: 25px !important;
}

.css-1q6at85-MuiInputBase-root-MuiOutlinedInput-root {
    border-radius: 26px !important;
}

.css-1ufn0jl {
    height: 35px !important;
    border-radius: 20px !important;
}

.mbutton {
    margin-bottom: 120px !important;
}


b.thead,
b.tbody {
    display: block;
}

b.tbody {
    height: 345px;
    /* overflow-y: auto;
    overflow-x: hidden; */
    text-align: left;
    color: #000000;

}

b.thead {
    color: #707070;
    text-align: left;
}




b.table {
    border-collapse: collapse;
    width: 100%;
    table-layout: fixed;
}

b.th,
td {
    padding: 12px;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
}

.tableborder {
    border: 0.1px solid #D3D3D3;
    text-align: left;
    margin-right: 50px;
}

.scrollit {
    /* overflow:scroll; */
    height: 55vh;
    overflow-y: auto;
    margin-top: 20px;
    margin-bottom: 20px;
    table-layout: fixed !important;
    /* border-collapse: collapse; */
}

.scrolldown {
    /* overflow:scroll; */
    height: 80dvh;
    overflow-y: auto;
    /* border-collapse: collapse; */
}

.scrollit thead th {
    position: sticky;
    top: 0px;

    /* z-index: 10px !important; */
    background-color: #FFFFFF;
}

.scrollit th {
    word-wrap: break-word;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    text-align: left;
    padding: 12px;
    z-index: 10px;
}


td {
    word-wrap: break-word;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
}

tbody {
    text-align: left;
    color: #000000 !important;

}

thead {
    color: #707070;
    text-align: left;
    position: sticky;
}

input[type="checkbox"] {
    width: 17.83px;
    height: 14.51px;
    border: 1px solid #707070;
    border-radius: 5px;
}

.dotr {
    height: 13px;
    width: 13px;
    background-color: red;
    border-radius: 50%;
    display: inline-block;
    margin-right: 8px;
}

.dotg {
    height: 13px;
    width: 13px;
    background-color: green;
    border-radius: 50%;
    display: inline-block;
    margin-right: 8px;
}

.record {
    margin-top: 10%;
    margin-left: 40%;
}

.curser {
    color: blue
}

.page {
    position: relative;
    top: 70%;
    left: 35%;
}

.pagesam {
    position: relative;
    top: 5%;
    left: 13%;
    bottom: 6px;
}

.pageorg {
    position: relative;
    top: 5%;
    left: 40%;
}

#useredit {
    position: absolute;
    left: 3%;
}

.pagenum {
    position: absolute;
    left: 65%;
    bottom: 17%;
    font-size: 16px;
    font-style: normal;
    color: #707070;

}

.css-mnn31 {
    font-weight: 400 !important;
    font-size: 14px !important;
    font-family: 'MontserratRegular' !important;
    color: #545454 !important;
}
.filtermain{
    position: absolute;
    left: 40%;
    top:0.5%;
}
.butnset{
    position: absolute;
    left:65%;
    top:45px;
}
.butnsera{
    position: absolute;
    left:67%;
    top:45px ; 
}
.checkbox{
    margin-left:28%;
     position: absolute;
     top: 109.3%;
     height:19px !important;
     width: 19px !important;
}
.cir1{
    background: #e89ca3;
    border-radius: 50%;
    width: 55px;
    height: 55px;
}
.cir2{
    background: #e994b1;
    border-radius: 50%;
    width: 55px;
    height: 55px;
    position: relative;
   right:7px;
   
}
.cir3{
    background: #d8a0e1;
    border-radius: 50%;
    width: 55px;
    height: 55px;
    position: relative;
   right:0.5px;
   
}
.cir4{
    background:#bfaddf;
    border-radius: 50%;
    width: 55px;
    height: 55px;
    position: relative;
   left:8px;
   
}
.cir5{
    background: #aeb4e2;
    border-radius: 50%;
    width: 55px;
    height: 55px;
    position: relative;
   left:15px;
   
}
.cir6{
    background: #8fbadd;
    border-radius: 50%;
    width: 55px;
    height: 55px;
    position: relative;
   left:22px;
   
}
.cir7{
    background: #87bdd6;
    border-radius: 50%;
    width: 55px;
    height: 55px;
    position: relative;
   left:28px;
   
}
.cir8{
    background: #81bec8;
    border-radius: 50%;
    width: 55px;
    height: 55px;
}
.cir9{
    background:#82c0ba;
    border-radius: 50%;
    width: 55px;
    height: 55px;
    position: relative;
   right:7px;
   
}
.cir10{
    background: #81c183;
    border-radius: 50%;
    width: 55px;
    height: 55px;
    position: relative;
   right:0.5px;
   
}
.cir11{
    background:  #b6bb7e;
    border-radius: 50%;
    width: 55px;
    height: 55px;
    position: relative;
   left:8px;
   
}
.cir12{
    background: #beb87f;
    border-radius: 50%;
    width: 55px;
    height: 55px;
    position: relative;
   left:15px;
   
}
.cir13{
    background:  #d1b07e;
    border-radius: 50%;
    width: 55px;
    height: 55px;
    position: relative;
   left:22px;
   
}
.cir14{
    background: #dfa592;
    border-radius: 50%;
    width: 55px;
    height: 55px;
    position: relative;
   left:28px;
   
}
/* new */
.cirActive1{
    background: #d53e4b;
    border-radius: 50%;
    width: 55px;
    height: 55px;
}
.cirActive2{
    background:#d72e68;
    border-radius: 50%;
    width: 55px;
    height: 55px;
    position: relative;
   right:7px;
   
}
.cirActive3{
    background:#b545c8;
    border-radius: 50%;
    width: 55px;
    height: 55px;
    position: relative;
   right:0.5px;
   
}
.cirActive4{
    background:#8360c4;
    border-radius: 50%;
    width: 55px;
    height: 55px;
    position: relative;
   left:8px;
   
}
.cirActive5{
    background:#626eca;
    border-radius: 50%;
    width: 55px;
    height: 55px;
    position: relative;
   left:15px;
   
}
.cirActive6{
    background:#2379c0;
    border-radius: 50%;
    width: 55px;
    height: 55px;
    position: relative;
   left:22px;
   
}
.cirActive7{
    background:#147fb2;
    border-radius: 50%;
    width: 55px;
    height: 55px;
    position: relative;
   left:28px;
   
}
.cirActive8{
    background:#078296;
    border-radius: 50%;
    width: 55px;
    height: 55px;
}
.cirActive9{
    background:#0a857a;
    border-radius: 50%;
    width: 55px;
    height: 55px;
    position: relative;
   right:7px;
   
}
.cirActive10{
    background:#07870c;
    border-radius: 50%;
    width: 55px;
    height: 55px;
    position: relative;
   right:0.5px;
   
}
.cirActive11{
    background:#727b02;
    border-radius: 50%;
    width: 55px;
    height: 55px;
    position: relative;
   left:8px;
   
}
.cirActive12{
    background:#827503;
    border-radius: 50%;
    width: 55px;
    height: 55px;
    position: relative;
   left:15px;
   
}
.cirActive13{
    background:#a76502;
    border-radius: 50%;
    width: 55px;
    height: 55px;
    position: relative;
   left:22px;
   
}
.cirActive14{
    background:#c44f2a;
    border-radius: 50%;
    width: 55px;
    height: 55px;
    position: relative;
   left:28px;
   
}
.close{
    position: relative;
    right: 25px;
    bottom: 30px;
}
.cirmiddle{
    position: absolute;
    left:45px;
}
.cirmiddle2{
    margin-left:20px;
}
.cirmiddle3{
    position: absolute;
    left:45px; 
   top:275px;
   
}
.cirmiddle4{
    margin-left:20px;
   position: relative;
   bottom:3px;
}
.cirmiddless{
    position: absolute;
    bottom: 145px;
    right:165px; 
}
.scrollit2 {
    height: 75vh;
    overflow-y: auto;
    /* table-layout: fixed !important; */
   
}
.color-palette-label-hide {
    display: none; 
}
.cirActive1a{
    background: #d53e4b;
    border-radius: 50%;
    width: 35px;
    height: 35px;
    left:28px;
    bottom: 20px;
}
.cirActive2a{
    background:#d72e68;
    border-radius: 50%;
    width: 35px;
    height: 35px;
    position: relative;
    left:28px;
    bottom: 20px;
   
}
.cirActive3a{
    background:#b545c8;
    border-radius: 50%;
    width: 35px;
    height: 35px;
    position: relative;
    left:28px;
    bottom: 20px;
   
}
.cirActive4a{
    background:#8360c4;
    border-radius: 50%;
    width: 35px;
    height: 35px;
    position: relative;
    left:28px;
    bottom: 20px;
   
}
.cirActive5a{
    background:#626eca;
    border-radius: 50%;
    width: 35px;
    height: 35px;
    position: relative;
    left:28px;
    bottom: 20px;
   
}
.cirActive6a{
    background:#2379c0;
    border-radius: 50%;
    width: 35px;
    height: 35px;
    position: relative;
    left:28px;
    bottom: 20px;
   
}
.cirActive7a{
    background:#147fb2;
    border-radius: 50%;
    width: 35px;
    height: 35px;
    position: relative;
    left:28px;
    bottom: 20px;
   
}
.cirActive8a{
    background:#078296;
    border-radius: 50%;
    width: 35px;
    height: 35px;
    left:28px;
    bottom: 20px;
}
.cirActive9a{
    background:#0a857a;
    border-radius: 50%;
    width: 35px;
    height: 35px;
    position: relative;
    left:28px;
    bottom: 20px;
   
}
.cirActive10a{
    background:#07870c;
    border-radius: 50%;
    width: 35px;
    height: 35px;
    position: relative;
   left:28px;
   bottom: 20px;
   
}
.cirActive11a{
    background:#727b02;
    border-radius: 50%;
    width: 35px;
    height: 35px;
    position: relative;
   left:28px;
   bottom: 20px;
   
}
.cirActive12a{
    background:#827503;
    border-radius: 50%;
    width: 35px;
    height: 35px;
    position: relative;
   left:28px;
   bottom: 20px;
   
}
.cirActive13a{
    background:#a76502;
    border-radius: 50%;
    width: 35px;
    height: 35px;
    position: relative;
   left:28px;
   bottom: 20px;
   
}
.cirActive14a{
    background:#c44f2a;
    border-radius: 50%;
    width: 35px;
    height: 35px;
    position: relative;
   left:28px;
   bottom: 20px;
   
}
.round{
    color:#0F4391;
   position: relative;
   bottom:54px;
   left: 93px;
   font-size: 10px;
   font-weight: 600;
}
.mainhead{
    font-size:15px !important;
    color: #454545;
    
}
.iconadd{
    position: relative;
    left: 320px;
}