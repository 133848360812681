.m-b-f{
  font-family:'MontserratBold'
}

.m-m-f{
  font-family:'MontserratMedium'
}

.m-r-f{
  font-family:'MontserratRegular'
}

.m-s-f{
  font-family:'MontserratSemiBold'
}

.m-s-n{
  font-family:'Montserrat'
}
.css-imf0nl-MuiDataGrid-root{
  font-weight: 400 !important;
font-size: 14px !important;
  font-family:'MontserratRegular' !important
}
.font-normal{
  font-weight: 400;
  font-size: 14px;
    font-family:'MontserratRegular' !important
}
.text-lg {
  font-size: 14px;
  line-height: 20px;
}
.form-heading-org{
  font-weight: 500 !important;
  font-size: 12px !important;
  line-height: 15px !important;
    font-family:'MontserratRegular' !important
}
.css-1x51dt5-MuiInputBase-input-MuiInput-input{
  font-weight: 400 !important;
font-size: 14px !important;
  font-family:'MontserratRegular' !important;
  color: #545454 !important;
  border: 1px solid #DBDADA;
  
}

/* .MuiInput-input{
  border-bottom:1px solid red !important;
  
} */

.setcolor{
   color: #707070;
   padding-top: 20px;
}
::-webkit-input-placeholder { /* Chrome/Opera/Safari */
  color: #8A8A8A !important;
  font-family:'MontserratRegular';
}
::-moz-placeholder { /* Firefox 19+ */
  color: #8A8A8A !important;
  font-family:'MontserratRegular';
}
:-ms-input-placeholder { /* IE 10+ */
  color: #8A8A8A !important;
  font-family:'MontserratRegular';
}
:-moz-placeholder { /* Firefox 18- */
  color: #8A8A8A !important;
  font-family:'MontserratRegular';
}
.css-177gid-MuiTableCell-root{
  padding: 5px !important;
}
.css-11w94w9-MuiTableCell-root{
  font-family: 'Montserrat'!important;
  font-style: normal !important;
  font-weight: 400 !important;
  size: 14px !important;
 color: #000000 !important;
}
.css-1ygcj2i-MuiTableCell-root{
  font-family: 'Montserrat'!important;
  font-style: normal !important;
  font-weight: 400 !important;
  size: 14px !important;
 color: #707070 !important;
 font-size: 14px;
}

.css-1xy1myn-MuiDataGrid-root .MuiDataGrid-cellContent{
  font-family: 'Montserrat'!important;
  font-style: normal !important;
  font-weight: 400 !important;
  size: 14px !important;
 color: #000000 !important;
 font-size: 14px
}
.css-1jbbcbn-MuiDataGrid-columnHeaderTitle{
  font-family: 'Montserrat'!important;
  font-style: normal !important;
  font-weight: 400 !important;
  size: 14px !important;
 color: #707070 !important;
 font-size: 14px
}

.css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root{
  border-radius: 10px !important;
  border-color: #707070;
  padding: 4px;
}
.css-i4bv87-MuiSvgIcon-root{
   /* padding: 4px;
   margin-right: 10px; */
}
.css-1qgma8u-MuiButtonBase-root-MuiTableSortLabel-root{
  color: #707070 !important;
}

.org-pad {
  padding: 10px;
}

.admin_side_menu {
  font-family: 'MontserratSemiBold' !important;
font-style: bold !important;
font-weight: 500 !important;
font-size: 16px !important;
line-height: 20px !important;
color: #0F4391 !important;
}


.admin_side_menu_desc {
  font-family: 'MontserratRegular'!important;
font-style: normal;
font-weight: 300;
font-size: 12px;
line-height: 15px;
color: #454545;
/* padding: 5px; */
}
.content{
  align-content: center;
  margin: auto;
  text-align: center;
  display: grid;
  /* background-color: red; */
  width: max-content;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 100vh;
  align-items: center;
  justify-items: center;
  
  /* padding: 10px; */
}


.marginh{
  margin-top: 40px;
}
.marginh1{
  margin-top: 20px;
}
.mwidth{
  width: 374px;
}
.imgcontainer{
  width: 540px;
 
  /* height: 30px; */
}
.contentlogo{
font-size: 8px;
line-height: 9px;
color: #0F4391;
margin-top: 5px;
margin-left: 51px;
opacity: 1;
letter-spacing: 0.9px;



}

.signin{
  font-weight: 400;
font-size: 20px;
line-height: 24px;

color: #545454;
}

.signcontainer{
  margin-top: 20px;
  border: 1px solid #0F4391 !important;
}
.signcontainererror{
  margin-top: 20px;
  border: 1px solid red !important;
}
.formsub{
  margin-top: 30px;
}
.signbutton{
  margin-top: 28px;
  margin-bottom: 28px;
}

.signinex{
  border: 1px solid #0F4391;
  color:  #0F4391;
  font-weight: 500;
font-size: 18px;
line-height: 22px;
border-radius: 20px;
width: 100%;
shape-image-threshold: rectangele;
}
/* style={{border: '1px solid #0F4391' ,  width:'100%' , height:'100%' , borderRadius:'9999px'  , backgroundColor:` ${isInputEmpty ? '#E8FAFF' : '#0F4391'}` , color:` ${isInputEmpty ? '#0F4391' : '#FFFFFF'}`}} */
