.boxcontent{
  position:fixed; 
  scroll-snap-type:y mandatory;
  overflow-y: scroll;
 
}
.maincontainer{
height: 100vh;
width: 93vw;
grid-template-rows: auto auto;

}

.dashboard{
position: absolute;
left:2.5px;
top:2.8px;
/* bottom: 30px; */
background: #FFFFFF;
}
.dashboard1{
  position: absolute;
  left:2.5px;
  top:3.9px;
  /* bottom: 30px; */
  background: #FFFFFF;
  }

.maincontainer1 {
  height: 100vh;
width: 77vw;
grid-template-rows: auto auto;
 
}
/* Add this CSS to your project's stylesheet */

.cookie-consent-banner {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: #333;
  color: #fff;
  text-align: center;
  padding: 10px 0;
  z-index: 999;
}

.cookie-text {
  margin: 0;
  font-size: 14px;
}

.privacy-link {
  color: #fff;
  text-decoration: underline;
  margin-left: 4px;
}

.button-container {
  margin-top: 10px;
}

.accept-button,
.decline-button {
  background-color: #4caf50;
  color: #fff;
  border: none;
  padding: 8px 16px;
  margin: 0 8px;
  cursor: pointer;
  border-radius: 4px;
  font-size: 14px;
}

.accept-button:hover,
.decline-button:hover {
  background-color: #45a049;
}
