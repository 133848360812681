.package-filter-btn {
    position: relative;
}

.package-filter-btn .filter-text {
    margin-right: 15px;
}

.package-filter-btn .filter-icon {
    position: absolute;
    width: 10px;
    top: 9px;
    right: 8px;
}