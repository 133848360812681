
b.thead,
b.tbody {
    display: block;
}

b.tbody {
    height: 345px;
    overflow-y: auto;
    overflow-x: hidden;
    text-align: left;
}

b.thead {
    color: #707070;
    text-align: left;
}

b.table,
td,
th {
    border-bottom: 1px solid #ddd;

}


b.table {
    border-collapse: collapse;
    /* width: 100%; */
    
}

b.th,
td {
    padding: 12px;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
}