
@tailwind base;

@tailwind components;

@font-face {
    font-family: "MontserratBold";
    src: local("MontserratBold"),
    url("./fonts/Montserrat-Bold.ttf") format("truetype");
    font-weight: bold;
    }

@font-face {
   font-family: "MontserratMedium";
   src: local("MontserratMedium"),
   url("./fonts/Montserrat-Medium.ttf") format("truetype");
   font-weight: medium;
  }
@font-face {
   font-family: "MontserratRegular";
   src: local("MontserratRegular"),
   url("./fonts/Montserrat-Regular.ttf") format("truetype");
   font-weight: normal;
  }
@font-face {
   font-family: "MontserratSemiBold";
   src: local("MontserratSemiBold"),
   url("./fonts/Montserrat-SemiBold.ttf") format("truetype");
   font-weight: 600;
  }

  section.opensidebar {
    position: fixed;
    left: 107px;
    top: 49px;
    /* height: 100vh; */
    /* z-index: -32; */
    transition: all 0.3s ease-in-out;
    /* margin-right: 67px; */
    overflow: hidden;
    margin: 0;
     /* height: 100%; */
}
main.openmenu {
    padding-left: 240px;
    position: relative;
}   

.tablemanage{
  height: 410px;
  width: 95%;
  padding-bottom: 5px;
}
.h-custom-height-4 {
  height: calc(92vh - 48px)!important;
  position: relative
  
}
.w-1\/2 {
  width: 43% !important;
}
.justcont{
  justify-content: space-between
}


@tailwind utilities;