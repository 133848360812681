.width-color{
position: relative;
left:3%;
right: 20.06%;
top: 55.61%;
width: 350px;
bottom: 46.85%;
border: 1px solid #cac5c5;
border: 1px solid #0F4391;
}
::-webkit-scrollbar{
    display: none;
}
#ADD{
position: relative;
left:3%;
color: #454545;
}
#Org{
color: #454545;
}
#CON{
    position: relative;
    left: 3%;
}
#maindiv{
height: 900px;
width: 700px !important;
}
#main1div{
    height:900px;
    width: 675px !important;
}
#div{
    height: 30px;
}
.organ{
height:10px !important;
left: 89px !important;
font-weight: 500 !important;
font-size: 14px !important;
line-height: 17px !important;
font-family: 'Montserrat';
color: #0F4391;
font-style: normal;
}
.heading{
font-weight: 300 !important;
font-size: 12px !important;
line-height: 15px !important;
color: #454545;
font-family: 'Montserrat';
font-style: normal;
}
.organbox{
    height: 30px;
    position:relative;
    right:5px;
    padding-top: 1px;
    bottom: 3px;
}



