.curseridmain {
  height: 38px;
  width: 38px;
  border: 10px;
  border-radius: 50%;
  position: relative;
  left: 23%;
}

.menu {
  color: white;
  position: relative;
  right: 3px;
  bottom: 5px;
  font-size: 15px;
}

.menu2 {
  color: white;
  position: relative;
  left: 9px;
  top: 6px;
  bottom: 1px;
  font-size: 15px;
}

.logoutid {
  position: relative;
  right: 20%;
}

.changepasswoedid {
  position: relative;
  right: 15%;
}

/* .css-1poimk-MuiPaper-root-MuiMenu-paper-MuiPaper-root-MuiPopover-paper{
    width:430px;
    margin-top: 34px;
    border: solid rgb(125, 119, 119) 1px;
} */

.curserid {
  height: 30px;
  width: 40px;
  border: 10px;
  border-radius: 50%;
  position: relative;
  left: 5%;
  top: 3px;
  cursor: pointer;
}

.profileIconContainer {
  position: relative;
  left: 25%;
  bottom: 50px;
}

.center {
  display: flex;
  justify-content: "center";
  align-items: "center";
}

.profile {
  position: relative;
  bottom: 9px;
  right: 8px;
  font-size: 21px;
  height: 40px;
  width: 40px;
}

.profile1 {
  position: relative;
  bottom: 9px;
  font-size: 21px;
}





