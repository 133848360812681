#aside{
    width:80px;
}
.addicon{
font-family: 'Montserrat';
font-style: normal;
font-weight: 400;
font-size: 10px;
line-height: 12px;
text-align: center;
color: #545454
}
div.item {
    /* vertical-align: top; */
    display: inline-block;
    text-align: center;
}
/* img {
    margin-left: 10px;
} */
.caption {
    display: block;
    font-weight: 500;
font-size: 10px;
line-height: 12px;
}