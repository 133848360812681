.bulk-imports-header {
    margin-left: 10px;
    border-bottom: 1px solid #bbbcc0;
    font-size:28px;
}

.bulk-imports-form {
    margin-left: 110px;
    padding-left: 10%;
    margin-top: 30px;
}

.bulk-imports-form .form-field-container {
    display: flex;
    margin: 15px 0 0;
    align-items: baseline;
}

.bulk-imports-form .form-field-container.button-container {
    margin-left: 160px;
    margin-top: 80px;
    
    
}
.download-type-container {
    margin-left:190px;
    color: #0000FF;
}



.bulk-imports-form .form-field-container .input-file-field {
    margin-left: 40px;
    border: 1px solid #bbbcc0;
    width: 360px;
    padding: 5px;
    min-height: 35px;
    height: 50px;
    border-radius: 4px;
   
}

.record-type-field {
    min-width: 400px;
    margin-left: 20px;
}

.record-type-field .MuiSelect-select {
    padding: 7px;
    text-align: left;
}

.bulk-imports-form .form-field-container .input-file-field.input-file-field-error {
    border: 1px solid #d32f2f;
}

.file-error {
    display: block;
    color: #d32f2f;
    margin: 0 0 15px 185px;
}

.file-text {
    display: none;
}
