.Sidenav{
    width: 237px !important;
}
.Downflour{
background-color: blue;
}
.spanimg{
    height: 11px;
    width: 10.941396713256836px;
    left: 263.0001220703125px;
    border-radius: 50%;
    border: 2px solid #0F4391;
    height: 20.354774475097656px;
    width: 20.354774475097656px;
    position: absolute;
    left:86%;
}
.admin{
    font-family: 'Montserrat';
font-style: normal;
font-weight: 500;
font-size: 16px;
line-height: 20px;
color: #0F4391
}